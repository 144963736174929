.project_wrapper_section{
    color: white;
}
.black_portfolio{
  background: var(--black2) ;
}
.black_portfolio{
  background: var(--black3) !important;
}
.Left_section_project{
    display: flex;
    gap: 1rem;
}
.h_pro{
    font-size: 1.5rem;
    color:#64f4ab;
}
.heading_pro{
    font-size: 2.2rem;
    cursor: pointer;

}
.para_pro{
    font-size: 1.2rem;
    color:  rgb(175, 166, 166);;
}
.readmore_project{
    font-size: 1.4rem;
    color: #fecd1a;
    gap: 2rem;
    display: flex;
    cursor: pointer;
}
.project_1{
    border-radius: 2px solid red;
}


/*project 1 image*/
.image_container_project img{
    border-radius: 50px;
 
}
.project_1{
    margin-top: 120px;
}

/*shadow curve*/

  .image_container_project img {
    transform: rotate(4deg);
    box-shadow: 0 0 10px var(--black2);
  }
  .image_container_project {
    transform: rotate(-6deg);
    margin-bottom: 0px;
  }
  
  .image_container_project img {
    box-shadow: 46px 19px 0px 2px rgba(0, 0, 0, 0.39);
  }
  .hero-container{
    display: flex;
    flex-direction: row;
  }

  /*////////////*/
  
  .image_container_project {
    /* Set initial position and hide the image */
 }
 
 .image_container_project img {
    /* Set transition property for smooth animation */
    transition: transform 1s ease-in-out;
 }
 
 .image_container_project.visible img {
    /* Define the animation */
    animation: moveImage 1s backwards;
    opacity: 1;
 }
 
 @keyframes moveImage {
    /* Define the movement of the image */
    0% {
       transform: translateX(100px);
    }
    100% {
       transform: translateX(0);
    }
 }


  .image_container_project {
    position: relative;
    height: 100vh; /* Adjust the height as needed */

  }


 
 /*project 2 css--------------------------------------------------------------*/

 .image_container_project_2 img{
  border-radius: 50px;

}

.image_container_project_2 img {
  transform: rotate(4deg);
  box-shadow: 0 0 10px var(--black2);
}
.image_container_project_2 {
  transform: rotate(2deg);
  margin-bottom: 0px;
}

.image_container_project_2 img {
  box-shadow: 46px 19px 0px 2px rgba(0, 0, 0, 0.39);
}



.image_container_project_2 img {
  /* Set transition property for smooth animation */
  transition: transform 1s ease-in-out;
}

.image_container_project_2.visible img {
  /* Define the animation */
  animation: moveImage 1s backwards;
  opacity: 1;
}

@keyframes moveImage {
  /* Define the movement of the image */
  0% {
     transform: translateX(100px);
  }
  100% {
     transform: translateX(0);
  }
}


.image_container_project_2 {
  position: relative;
  height: 100vh; /* Adjust the height as needed */

}


.hero-container{
  display: flex;
  justify-content: space-between;
}

.flexColStart_2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 2.7rem;
}


/*button view All */

.bttn{
  background-color: white;
  margin-bottom: 10px;
}

.bttn:hover{
  background-color: black;
  color: white;
cursor: pointer;

}


@media (max-width:768px){

  .project_1{

  }
.pro1_screen_container{
  flex-direction: column !important;
  flex-direction: column-reverse !important;
  gap: -2rem !important;
}
  


.image_container_project img{
  width: 350px;
    margin-left: 33px;
}
.image_container_project {
  transform: rotate(-6deg);
  margin-bottom: -530px;
}
.Left_section_project {

}

.btnn_screen{
  height: 70px;
    font-size: 20px;
    margin-top: 48px;
}

.pro2_screen_container{
  flex-direction: column !important;
  gap: 8rem !important;
}
.image_container_project_2{
  margin-bottom: -530px;
}
.image_container_project_2 img{
  width: 350px;
  margin-left: -40px;
}
.flexColStart_2{
  margin-left: 0rem;
}
.project3{
  margin-top: 110px;
}
.project2{
  margin-top: 110px;
}
}